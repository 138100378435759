export default [
    { id: 10000002, key: 'Ayaka', name: 3628, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000003, key: 'Qin', name: 3629, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000005, key: 'PlayerBoy', name: 3630, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000006, key: 'Lisa', name: 3631, weapon: 'Catalyst', quailty: 'Purple' },
    { id: 10000007, key: 'PlayerGirl', name: 3632, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000014, key: 'Barbara', name: 3633, weapon: 'Catalyst', quailty: 'Purple' },
    { id: 10000015, key: 'Kaeya', name: 3634, weapon: 'SwordOneHand', quailty: 'Purple' },
    { id: 10000016, key: 'Diluc', name: 3635, weapon: 'Claymore', quailty: 'Orange' },
    { id: 10000020, key: 'Razor', name: 3636, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000021, key: 'Ambor', name: 3637, weapon: 'Bow', quailty: 'Purple' },
    { id: 10000022, key: 'Venti', name: 3638, weapon: 'Bow', quailty: 'Orange' },
    { id: 10000023, key: 'Xiangling', name: 3639, weapon: 'Pole', quailty: 'Purple' },
    { id: 10000024, key: 'Beidou', name: 3640, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000025, key: 'Xingqiu', name: 3641, weapon: 'SwordOneHand', quailty: 'Purple' },
    { id: 10000026, key: 'Xiao', name: 3642, weapon: 'Pole', quailty: 'Orange' },
    { id: 10000027, key: 'Ningguang', name: 3643, weapon: 'Catalyst', quailty: 'Purple' },
    { id: 10000029, key: 'Klee', name: 3644, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000030, key: 'Zhongli', name: 3645, weapon: 'Pole', quailty: 'Orange' },
    { id: 10000031, key: 'Fischl', name: 3646, weapon: 'Bow', quailty: 'Purple' },
    { id: 10000032, key: 'Bennett', name: 3647, weapon: 'SwordOneHand', quailty: 'Purple' },
    { id: 10000033, key: 'Tartaglia', name: 3648, weapon: 'Bow', quailty: 'Orange' },
    { id: 10000034, key: 'Noel', name: 3649, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000035, key: 'Qiqi', name: 3650, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000036, key: 'Chongyun', name: 3651, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000037, key: 'Ganyu', name: 3652, weapon: 'Bow', quailty: 'Orange' },
    { id: 10000038, key: 'Albedo', name: 3653, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000039, key: 'Diona', name: 3654, weapon: 'Bow', quailty: 'Purple' },
    { id: 10000041, key: 'Mona', name: 3655, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000042, key: 'Keqing', name: 3656, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000043, key: 'Sucrose', name: 3657, weapon: 'Catalyst', quailty: 'Purple' },
    { id: 10000044, key: 'Xinyan', name: 3658, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000045, key: 'Rosaria', name: 3659, weapon: 'Pole', quailty: 'Purple' },
    { id: 10000046, key: 'Hutao', name: 3660, weapon: 'Pole', quailty: 'Orange' },
    { id: 10000047, key: 'Kazuha', name: 3661, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000048, key: 'Feiyan', name: 3662, weapon: 'Catalyst', quailty: 'Purple' },
    { id: 10000049, key: 'Yoimiya', name: 3663, weapon: 'Bow', quailty: 'Orange' },
    { id: 10000050, key: 'Tohma', name: 3664, weapon: 'Pole', quailty: 'Purple' },
    { id: 10000051, key: 'Eula', name: 3665, weapon: 'Claymore', quailty: 'Orange' },
    { id: 10000052, key: 'Shougun', name: 3666, weapon: 'Pole', quailty: 'Orange' },
    { id: 10000053, key: 'Sayu', name: 3667, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000054, key: 'Kokomi', name: 3668, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000055, key: 'Gorou', name: 3669, weapon: 'Bow', quailty: 'Purple' },
    { id: 10000056, key: 'Sara', name: 3670, weapon: 'Bow', quailty: 'Purple' },
    { id: 10000057, key: 'Itto', name: 3671, weapon: 'Claymore', quailty: 'Orange' },
    { id: 10000058, key: 'Yae', name: 3672, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000059, key: 'Heizo', name: 3673, weapon: 'Catalyst', quailty: 'Purple' },
    { id: 10000060, key: 'Yelan', name: 3674, weapon: 'Bow', quailty: 'Orange' },
    { id: 10000061, key: 'Momoka', name: 3675, weapon: 'SwordOneHand', quailty: 'Purple' },
    { id: 10000062, key: 'Aloy', name: 3676, weapon: 'Bow', quailty: 'OrangeSp' },
    { id: 10000063, key: 'Shenhe', name: 3677, weapon: 'Pole', quailty: 'Orange' },
    { id: 10000064, key: 'Yunjin', name: 3678, weapon: 'Pole', quailty: 'Purple' },
    { id: 10000065, key: 'Shinobu', name: 3679, weapon: 'SwordOneHand', quailty: 'Purple' },
    { id: 10000066, key: 'Ayato', name: 3680, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000067, key: 'Collei', name: 3681, weapon: 'Bow', quailty: 'Purple' },
    { id: 10000068, key: 'Dori', name: 3682, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000069, key: 'Tighnari', name: 3683, weapon: 'Bow', quailty: 'Orange' },
    { id: 10000070, key: 'Nilou', name: 3684, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000071, key: 'Cyno', name: 3685, weapon: 'Pole', quailty: 'Orange' },
    { id: 10000072, key: 'Candace', name: 3686, weapon: 'Pole', quailty: 'Purple' },
    { id: 10000073, key: 'Nahida', name: 3687, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000074, key: 'Layla', name: 3688, weapon: 'SwordOneHand', quailty: 'Purple' },
    { id: 10000075, key: 'Wanderer', name: 3689, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000076, key: 'Faruzan', name: 3690, weapon: 'Bow', quailty: 'Purple' },
    { id: 10000077, key: 'Yaoyao', name: 3691, weapon: 'Pole', quailty: 'Purple' },
    { id: 10000078, key: 'Alhatham', name: 3692, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000079, key: 'Dehya', name: 3693, weapon: 'Claymore', quailty: 'Orange' },
    { id: 10000080, key: 'Mika', name: 3694, weapon: 'Pole', quailty: 'Purple' },
    { id: 10000081, key: 'Kaveh', name: 3695, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000082, key: 'Baizhuer', name: 3696, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000083, key: 'Linette', name: 3697, weapon: 'SwordOneHand', quailty: 'Purple' },
    { id: 10000084, key: 'Liney', name: 3698, weapon: 'Bow', quailty: 'Orange' },
    { id: 10000085, key: 'Freminet', name: 3699, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000086, key: 'Wriothesley', name: 3700, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000087, key: 'Neuvillette', name: 3701, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000088, key: 'Charlotte', name: 3702, weapon: 'Catalyst', quailty: 'Purple' },
    { id: 10000089, key: 'Furina', name: 3703, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000090, key: 'Chevreuse', name: 3704, weapon: 'Pole', quailty: 'Purple' },
    { id: 10000091, key: 'Navia', name: 3705, weapon: 'Claymore', quailty: 'Orange' },
    { id: 10000092, key: 'Gaming', name: 3706, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000093, key: 'Liuyun', name: 3707, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000094, key: 'Chiori', name: 3708, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000095, key: 'Sigewinne', name: 3709, weapon: 'Bow', quailty: 'Orange' },
    { id: 10000096, key: 'Arlecchino', name: 3710, weapon: 'Pole', quailty: 'Orange' },
    { id: 10000097, key: 'Sethos', name: 3711, weapon: 'Bow', quailty: 'Purple' },
    { id: 10000098, key: 'Clorinde', name: 3712, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000099, key: 'Emilie', name: 3713, weapon: 'Pole', quailty: 'Orange' },
    { id: 10000100, key: 'Kachina', name: 3714, weapon: 'Pole', quailty: 'Purple' },
    { id: 10000101, key: 'Kinich', name: 3715, weapon: 'Claymore', quailty: 'Orange' },
    { id: 10000102, key: 'Mualani', name: 3716, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000103, key: 'Xilonen', name: 3717, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000104, key: 'Chasca', name: 3718, weapon: 'Bow', quailty: 'Orange' },
    { id: 10000105, key: 'Olorun', name: 3719, weapon: 'Bow', quailty: 'Purple' },
    { id: 10000106, key: 'Mavuika', name: 3720, weapon: 'Claymore', quailty: 'Orange' },
    { id: 10000107, key: 'Citlali', name: 3721, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000108, key: 'Lanyan', name: 3722, weapon: 'Catalyst', quailty: 'Purple' },
]
